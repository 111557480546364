/* Copyright (C) TechRamp, LLC - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Scott Laughlin <scott@techramp.io>, May 2024
 */

/* Global Settings */
* {
  font-family: Exo2, sans-serif;
  margin: 0;
  padding: 0;
  /* box-sizing: border-box; */
}

/* Container for the content */
.landing-page-container {
  padding: 10px;
  background-color: #F3F4F9;
  width: 100%;
  height: 100%;  /* Ensure minimum height to cover viewport */
  display: flex;         /* Enable flexbox layout */
  flex-direction: column; /* Align items vertically */ 
  box-sizing: border-box;
}

/* Container for the entire landing page */
.landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 8px;
  min-height: 40vh; /* Ensure it covers at least the viewport height */
  padding: 20px; /* Top padding to account for fixed header */
  background-color: #FFF;
  flex-grow: 1;
}

/* Logo */
.logo {
  width: 25vw; /* Responsive width */
  min-width: 250px;
  height: auto;
}

.login-button:hover {
  background-color: #0056b3;
}

.welcome {
  background: linear-gradient(to  right, #FF6B00, #00C9FF); 
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  display: inline-block;
}

/* Description */
.description {
  font-size: 2vw; /* Responsive font size */
  text-align: center;
  color: #A3A3A3;
  padding: 20px 20px;  /* Added padding to ensure visibility and prevent clipping */
}

/* Description */
.subheading {
  font-size: 3vw; /* Responsive font size */
  text-align: center;

  padding: 20px 20px;  /* Added padding to ensure visibility and prevent clipping */
}


/* Character Rotation Section */
.character-rotation {
  display: flex;
  justify-content: space-around;
  width: 80%;
  margin-top: 20px; /* Additional top margin for separation */
  margin-bottom: 20px;
  background-color: #FFF;
  padding: 20px;
}

.character-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.character-icon {
  width: 15vw; /* Responsive width */
  height: auto;
  padding-bottom: 20px;
}

.character-info {
  text-align: center;
}

.project-form {
  display: flex;           /* Enable Flexbox layout */
  flex-direction: row;    /* Align items horizontally */ 
  align-items: center;    /* Center items vertically */
  width: 70%;          /* Take up about 2/3 of the available space */
  margin-bottom: 25px; 
}

.project-input {
  /* ... other styles ... */
  flex: 4;          /* Allow input to take up remaining space */
  margin-right: 10px;    /* Add spacing between input and button */ 
  height: 35px;
  border-radius: 8px;
  padding-left: 15px;
  border-color:#0056b3;
  border-style: solid;
}

.example-prompts-section {
  text-align: center; /* Center the text */
  margin-top: 20px; /* Add some space above */
  padding: 10px; /* Add some padding */
  margin: 10px;
  margin-bottom: 0px;
  background-color: #F3F4F9;
  border: 1px solid #ddd; /* Add a subtle border */
  border-radius: 5px; /* Round the corners */
  height: 110px;
  width: 200px;;
}

.example-prompts-section p {
  font-weight: bold; /* Make the "Try asking me:" text bold */
  margin-bottom: 5px; /* Add some space below the text */
}

.example-prompts-section ul {
  list-style: none; /* Remove the default bullet points */
  padding: 0; /* Remove default padding */
}

.example-prompts-section li {
  margin-bottom: 5px; /* Add space between list items */

}

.example-prompts-section li:hover {
  text-decoration: none; /* Remove underline on hover */
}

.idea-submit-button {
  padding: 10px;
  align-items: center;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* .idea-submit-button {
  display: flex;
  align-items: center; 
  justify-content: center;
  white-space: nowrap;
  align-items: center;
  height: 35px;
  padding: 0px;
  margin: 0px;
  margin-bottom: 0px;
  margin-top: 0px;
} */

/* LandingPage.css */
.error-message {
  color: red; 
  font-size: 0.8rem;
  margin-top: 5px; 
}

.try-demo-button {
  padding: 10px 20px;
  padding-left: 40px;
  padding-right: 40px;
  font-size: 1.25rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none; /* This removes the underline */
  margin: 10px;
  margin-bottom: 25px;
}

.try-demo-button:hover {
  background-color: #0056b3;
}

/* Media Queries */
@media (max-width: 768px) { /* Tablets and Mobile Phones */

  .landing-page-container{
    height: auto;
  }
  .logo {
    width: 25vw;
  }

  .description {
    font-size: 5vw; /* Adjusted for better readability on smaller devices */
  }

  .subheading {
    font-size: 4vw; /* Adjusted for better readability on smaller devices */
  }

  .character-rotation {
    flex-direction: column;
    align-items: center;
    margin-top: 20px; /* Increased margin to push characters below the top bar */
  }

  .character-section {
    width: 80%; /* Adjust width to prevent cutting off */
    margin-bottom: 20px;
  }

  .character-icon {
    width: 50vw;
  }

  .try-demo-button {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) { /* Mobile Phones */
  .top-bar {
    flex-direction: column;
    align-items: center;
  }

  .login-section {
    margin-top: 10px;
  }

  .character-rotation {
    flex-direction: column;
    align-items: center;
    margin-top: 20px; /* Increased margin to push characters below the top bar */
  }

  .character-section {
    width: 80%; /* Adjust width to prevent cutting off */
    margin-bottom: 20px;
  }

  .character-icon {
    width: 60vw; /* Larger to fill space appropriately */
    margin: 20px
  }

  .description {
    font-size: 5vw; /* Adjusted font size for visibility */
    padding: 10px; /* Ensure text does not touch the screen edges */
  }

  .project-form {
    width: 90%;          /* Take up about 2/3 of the available space */
  }

  .project-input {
    font-size: 0.75rem;;
  }
}
