/* Copyright (C) TechRamp, LLC - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Scott Laughlin <scott@techramp.io>, May 2024
 */

/* LoginModal.css */
.login-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  padding: 20px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1002;
  border-radius: 8px;
  text-align: center;
}

.login-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1001;
}

.input-field {
  width: 100%; /* Ensures the field takes the full width of the modal */
  padding: 10px;
  margin: 8px 0;
  display: block; /* Ensures it takes a new line */
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.submit-button, .google-button, .facebook-button, .email-button {
  width: 90%;
  padding: 12px;
  margin: 10px auto; /* Centers the button in the modal */
  display: block; /* Ensures it takes a new line */
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  background-color: #007bff; /* Default blue */
}

.submit-button:hover, .google-button:hover, .facebook-button:hover, .email-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.google-button {
  background-color: #DB4437; /* Google red */
}

.facebook-button {
  background-color: #4267B2; /* Facebook blue */
}

.close-button {
  float: right;
  border: none;
  background: none;
  font-size: 1.2rem;
  cursor: pointer;
}

.social-login-section {
  margin-top: 20px; /* Space between email form and social buttons */
  border-top: 1px solid #ccc; /* Visual separation */
  padding-top: 20px;
}

.social-button {
  width: 90%;
  margin: 10px 0;
}

.toggle-form {
  margin-top: 10px;
  font-size: 14px;
}

.sign-in-up-mode-link {
  margin-top: 5px;
  font-size: 14px;
}