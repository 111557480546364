/* Copyright (C) TechRamp, LLC - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Scott Laughlin <scott@techramp.io>, May-Oct 2024
 */ 

 /* Style for the sessions header */
.sessions-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px; /* Add some space below the header */
  margin-left: 10px;
  margin-right: 10px;
}

/* Style for the new session button */
.new-session-button {
  background: none;
  align-items: right;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
  color: #007bff; /* Customize the color as needed */
}

.new-session-button:hover {
  color: #0056b3; /* Darken the color on hover */
}

 /* Sessions List */
.sessions-list {
  width: 100%;
  margin: 10px 0;
  padding: 0 15px;
}

.sessions-header h3 {
  margin-bottom: 10px;
  font-size: 0.9rem; /* Smaller header font size */
}

.sessions-list ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sessions-list li {
  padding: 4px 0;
  cursor: pointer;
}

.sessions-list li a {
  text-decoration: none;
  color: #333;
  font-size: 0.8rem; /* Smaller text size */
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sessions-list li a:hover {
  text-decoration: underline;
}

 body {
  overflow-x: hidden; /* Prevent horizontal scrolling */
 }


/* Bar.css */
.bar-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  transition: margin-left 0.3s ease-in-out;
  background-color: #F3F4F9;
}

.main-content {
  flex: 1; 
  margin-left: 20%; /* Add left margin equal to sidebar width */
  transition: margin-left 0.3s ease-in-out; /* Add transition */ 
}

/* Style for closed sidebar state */
.bar-container.closed .main-content { 
  margin-left: 7%;
}

.sidebar-divider {
  margin: 10px 0;   /* Adjust top/bottom margin as needed */
  width: 80%;      /* Adjust divider width as desired */
  border: 0;         /* Remove default border */
  height: 1px;      /* Thin line height */
  background-color: #ccc; /* Light gray color */ 
}

.minimize-container {
  display: flex;
  justify-content: flex-end; /* Align items to the end (right) */ 
  width: 90%;
}

/* Center the button ONLY when the sidebar is closed */ 
.sidebar.closed .minimize-container { 
  justify-content: center;
}

.minimize-button {
  display: flex;       /* Use flexbox for alignment */
  align-items: center; /* Vertically center the icon */
  padding: 8px;         /* Add some padding around the icon */ 
  background-color: #fff;
  border: none;
  img {
    width: 20px;  /* Set image width */
    height: 20px; /* Set image height (or use max-width/max-height) */
  }
}

.hamburger-button {
  display: none; /* Hide the button by default (desktop) */
  position: fixed; 
  top: 20px; 
  left: 20px;
  width: 40px;
  height: 40px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1001; /* Ensure it's above the sidebar */
}

.hamburger-button .bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  background-color: #333;
  transition: all 0.3s ease-in-out;
}

.sidebar-container {
  position: fixed;
  width: 20%; 
  height: 100vh;
  box-sizing:border-box;
  background-color: #F3F4F9;
  padding: 10px;     
  padding-right: 0px;
  transition: width 0.3s ease-in-out; /* Add a transition for smooth resizing */

}

/* Style for closed sidebar container */
.sidebar-container.closed { 
  width: 7%; /* Match the closed sidebar width */ 
}

.sidebar {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;   /* Add rounded corners */

  /* justify-content: space-between;  */
}

.sidebar.closed {
  width: 100%; /* closed width */
}

/* Hide elements in closed state */
.sidebar.closed 
.sidebar.closed .memory-section,
.sidebar.closed .nav-links-wrapper, 
.sidebar.closed .copyright  {
    display: none;
}

.sidebar.closed .minimize-button {
  justify-content: center; /* Center the icon */
}

/* Style the logo icon  */
.logo-icon {
  margin-top: 10px;
  width: 40px; /* Adjust size as needed */
  height: 40px; 
}

/* Logo - Smaller */
.logo-wrapper {
  box-sizing: border-box;
  margin: 10px 0;
  height: 52px;
  padding-bottom: 10px;
}

.logo {
  min-width: 70%;
  max-width: 100%;
  max-height: 50px;
  object-fit: scale-down;
}

.memory-section{
  flex-grow: 20;
}

.login-section {
  flex-grow: 0.5;
}

/* Login button */
.login-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  font-size: 1.2rem;
  border-radius: 5px;
  text-decoration: none;
}

.login-button:hover {
  background-color: #0056b3;
}

/* Logout button */
.logout-button {
  padding: 8px 16px;
  font-size: 14px;
  color: #555; 
  background-color: #f8f9fa; 
  border: 1px solid #ccc; 
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.logout-button:hover {
  background-color: #e2e6ea; 
  color: #333; 
}

/* Navigation Links */
.nav-links-wrapper {
  flex-grow: 0.5;
}

.nav-links {
  font-size: 0.8rem;
  display: flex; 
  align-items: center;  /* Center vertically */
}

.nav-link {
  text-decoration: none;
  color: #333;
  padding: 4px 8px;
}

.nav-link:hover {
  text-decoration: underline; 
}

.nav-separator {
  margin-top: 10px;
  border: none;
  border-top: 1px solid #ccc; 
}

/* Copyright */
.copyright {
  flex-grow: 0.5;
  font-size: 0.6rem; 
  color: #666; 
  text-align: center;
}

/* Tablet Styles */
@media (max-width: 1024px) {
  .sidebar {
    width: 15%; 
  }

  .main-content {
    margin-left: 15%; /* Adjust margin for sidebar width */
  }

  .nav-links {
    font-size: 0.6rem;
    display: flex; 
    align-items: center;  /* Center vertically */
  }

}

/* Mobile Styles */
@media (max-width: 768px) {
  .hamburger-button {
    display: block; /* Show the button on mobile */
  }

  /* Animation for open state */
  .hamburger-button.open .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .hamburger-button.open .bar:nth-child(2) {
    opacity: 0;
  }

  .hamburger-button.open .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  .sidebar-container.closed:not(.sidebar.open) {  /* Targets when .sidebar.open is NOT present */
    display: none; /* Hide the entire container when the sidebar is not open */
  }

  .sidebar-container.open {
    width: 100%;
  }

  .sidebar {
    padding: 10px;
    width: 100%; /* Wider sidebar for mobile */
    /* height: 100vh; */
    transform: translateX(-100%); /* Initially hidden off-screen */
    transition: transform 0.3s ease-in-out;
    background-color: #fff;
    z-index: 1000;
    left: -100%; 
  }

  .sidebar.open {
    transform: translateX(0); /* Slide in when open */
    left: 0;
  }

  .main-content {
    margin: 0px;
    padding: 0px;
    margin-left: 0; /* No margin when sidebar is off-screen */
  }

  .bar-container.closed .main-content {
    margin-left: 5px; /* No margin when sidebar is off-screen */
  }

  /* You might want to adjust character layout for mobile here */
  .character-row {
    flex-direction: column; /* Stack characters vertically */
  }
}