/* Container for the entire main console */
.main-console {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%; 
    box-sizing: border-box; 
    overflow: hidden;
    padding: 10px;
    background-color: #F3F4F9;
}

.loading-spinner {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #3498db;  /* Blue */
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 2s linear infinite;
    display: inline-block; 
    margin-left: 8px; /* Add some space */
    vertical-align: middle; /* Vertically center with the button text */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

/* CEO Panel */
.ceo-panel {
    display: flex;
    flex-direction: column; 
    flex: 0 0 40%;
    padding: 10px;
    margin-right: 10px;
    background-color: #fff; /* White background */
    border-radius: 8px;
    justify-content: space-between; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

/* Worker Section */
.worker-section {
    display: flex;
    flex-direction: column; /* Workers are side-by-side on desktop */
    height: 100%;
    width: 100%;
  }


/* Worker Container */
.worker-container { /* New class for the outer container */
    flex: 1;
    margin: 5px;
    border-radius: 8px;
    transition: flex 0.3s ease;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #fff;
    height: 100%;
  }

/* Worker Container SELECTED*/ 
.worker-container.selected{
    flex: 4;
}

/* .worker-container:last-child {
margin-right: 0;
} */


/* Worker Content */
.worker-pane {
    display: flex;
    min-height: 100vh;
    /* align-items: flex-start;  */
    background-color: #fff; 
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: none; 
    width: 100%; /* Ensure the content takes up the full width of the container */
    height: 100%;
    background-color: #fff;

  }

.worker-image {
    position: sticky;      /* Keep the image fixed */
    top: 10px;             /* Adjust vertical position as needed */ 
    /* ... other styles for the image ... */
}


.worker-pane:last-child {
    margin-bottom: 0; /* Remove bottom margin for last worker on mobile */
}


/* Form styling */
.console-form {
    width: 100%;
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: flex-start; /* Align items to the start */
}

.console-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    margin-bottom: 10px; /* Space between input and button */
    border-radius: 4px; /* Rounded corners */
    box-sizing: border-box;
}

.submit-button {
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px; /* Rounded corners */
    cursor: pointer;
}

.submit-button:hover {
    background-color: #0056b3;
}


/* Response and worker pane styling */
.response {
    overflow-y: auto; 
    margin-bottom: 5px; /* Add margin for spacing */
    word-wrap: break-word; /* Break words to prevent overflow */
    /* max-height: 100%; */
    /* width: 100%;  */
}

.agent-response {
    flex: 1;
    white-space: pre-wrap; /* Preserve whitespace and handle long words */
    word-wrap: break-word; /* Break words to prevent overflow */
    overflow-wrap: break-word; /* Ensure text wraps within the container */
    overflow-y: auto; /* Allow vertical scrolling */
    margin-left: 10px; /* Add space between image and text */
}

/* Mobile Styles */
@media (max-width: 768px) {

.main-console {
    flex-direction: column; /* Stack elements vertically */
    height: auto;
    padding: 3px;
    margin: 5px;
}

.ceo-panel {
    /* flex: 1; Take up full width */
    padding-right: 5px;
    margin-right: 5px; /* Remove right margin */
    margin-bottom: 10px; /* Add bottom margin */
    max-height: 1000px;
}

.worker-pane {
    max-height: 1000px;
}

}
