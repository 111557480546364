/* Copyright (C) TechRamp, LLC - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Scott Laughlin <scott@techramp.io>, May 2024
 */

/* demo-page.css */
.demo-page {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 0;
  padding: 10px;
  width: 100%;
  height: 100%;
  background-color: #F3F4F9; /* Set background color */
}

/* CEO Panel */
.ceo-panel {
  /* flex: 0 0 40%;  */
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-right: 20px;
  background-color: #fff; /* White background */
  border-radius: 8px; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 100%;
}

.ceo-image {
  /* flex: 1; */
  width: 100px;
  height: auto;
}

.ceo-title {
  flex: 1;
  font-size: 24px;
  margin: 5px;
  margin-bottom: 10px;
}

.ceo-subtext {
  font-size: 16px;
  margin-bottom: 10px;
  overflow-y: scroll;
  max-height: 100%;
  /* flex-grow: 1; */
}

.prompt {
  display: flex;
  flex-direction: column;
  height: 100%;
  /* margin-bottom: 20px;
  padding-bottom: 40px; */

}

.prompt-text {
  font-size: 16px;
  margin-bottom: 10px;
  
}

.start-button, .signup-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.start-button:hover, .signup-button:hover {
  background-color: #0056b3;
}

/* Worker Section */
.worker-section {
  display: flex;
  flex-direction: column; /* Workers are side-by-side on desktop */
  height: 100%;
}

.worker-container{
  flex-grow: 1;
}

.worker-container.selected{
  flex-grow: 5;
}


.worker-pane {
  align-items: flex-start; 
  background-color: #fff; 
  padding: 20px;
  margin-right: 10px;
  border-radius: 8px;
  transition: flex 0.3s ease;
  border: none;  /* Remove the blue border */
  height: 100%;
  flex-grow: 1;
}

/* .worker-pane:last-child {
  margin-right: 0;
} */

.worker-image {
  width: 80px;
  height: auto;
  margin-right: 20px;
}

/* Disclaimer */
.disclaimer {
  margin-top: 20px; 
  font-size: 0.8rem; 
  color: #666; 
  text-align: center; 
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .demo-page {
      flex-direction: column; 
  }

  .ceo-panel {
      /* margin-right: 0;  */
      margin-bottom: 0px;  /* Add bottom margin for spacing */
      width: 100%;
  }

  .worker-section {
    flex: 1;
    display: flex;
    flex-direction: row; /* Workers are side-by-side on desktop */
    /* width: 100%;  */
  }

  /* Worker Container */
.worker-container { /* New class for the outer container */
  flex-grow: 1;
  margin-right: 20px;
  border-radius: 8px;
  margin: 5px;
  transition: flex 0.3s ease;
}

.worker-container:last-child {
  margin-right: 0;
}

/* Selected Worker (Expanded) */
.worker-container.selected {
  flex: 20; 
}

/* Worker Content */
.worker-pane {
  display: flex;
  align-items: flex-start; 
  background-color: #fff; 
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: none; 
  width: 100%; /* Ensure the content takes up the full width of the container */
}

  .worker-pane:last-child {
      margin-bottom: 0; /* Remove bottom margin for last worker on mobile */
  }
}
