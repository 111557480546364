/* Copyright (C) TechRamp, LLC - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Scott Laughlin <scott@techramp.io>, June 2024
 */

 .user-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #F3F4F9;
    min-height: 100vh;
}

.user-profile h1 {
    font-size: 28px;
    color: #007bff;
    margin-bottom: 20px;
}

.profile-info {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 100%;
    max-width: 500px;
    margin-bottom: 20px;
}

.profile-info p {
    font-size: 16px;
    margin-bottom: 10px;
}

.profile-info strong {
    font-weight: bold;
    margin-right: 5px;
}

.upgrade-btn {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.upgrade-btn:hover {
    background-color: #0056b3;
}

@media (max-width: 768px) {
    .user-profile {
        padding: 10px;
    }

    .profile-info {
        width: 90%;
    }
}