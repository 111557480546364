.about-page-container {
    padding: 20px;
    font-family: sans-serif;
    height: auto;
    background-color: #F3F4F9;
  }
  
  .hero {
    background-color: #f2f2f2;
    padding: 0px;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .hero h2 {
    color: #333;
    margin-bottom: 15px;
  }
  
  .capabilities {
    margin-bottom: 20px;
  }
  
  .capabilities h3 {
    color: #444;
  }
  
  .capabilities ul {
    list-style-type: disc;
    padding-left: 40px;
  }

  .call-to-action {
    text-align: center; /* Center the button */
    margin-top: 30px; /* Add some spacing above */
  }
  
  .cta-button {
    background-color: #007bff; /* Example button color */
    color: white;
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
  }

  .hero-image {
    width: 100%; /* Make the image responsive */
    /* max-width: 800px; */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 20px; /* Add some space below the image */
  }

  .mission {
    margin-top: 20px; /* Add some space above */
    margin-bottom: 20px;
  }
  
  /* Add more styles for other sections as needed */