/* Copyright (C) TechRamp, LLC - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Scott Laughlin <scott@techramp.io>, June 2024
 */

  
/* SubscriptionsPage.css */
.subscriptions-page {
  display: flex;           /* Enable Flexbox for better layout control */
  flex-direction: column;  /* Arrange items vertically */
  align-items: center;    /* Center items horizontally */
  padding: 10px;        
  border-radius: 20px;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  background-color: F3F4F9;      
}

.pricing-table-container {
  width: 100%;
  height: 100%;
  padding-top: 10px;
               /* Take up full available width */
          /* Limit width for larger screens (adjust as needed) */
      /* Center horizontally with even top/bottom margins */

  /* Apply height styles to the container */
  background-color: white;
  display: flex;              /* Enable Flexbox on the container */
  flex-direction: column;     /* Align items within the container vertically */
}

/* **Important: Target the Stripe element directly ** */
stripe-pricing-table {
  
  width: 100%;                /* Ensure the Stripe component occupies the full container width */
              /* Reset any default padding */ 
}



.manage-button {
  margin-top: 10px;
  background-color: #0076D4; 
  color: white;
  padding: 10px 25px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.2s ease; /* Smooth hover effect */
  max-width: 250px;
}

.manage-button:hover {
  background-color: #364fc7; /* Darker blue on hover */
}

.subscription-details {
  width: 100%;
  max-width: 500px;
  background-color: white; 
  padding: 20px;
  border-radius: 8px;
  box-sizing: border-box;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */

  /* Make subscription-details take up at least the full viewport height */
  height: 100%; 
  display: flex; 
  flex-direction: column; /* Align items inside vertically */
}

.subscription-details p {
  margin-top: 8px;   /* Add spacing between paragraphs */
}